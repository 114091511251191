.quick-pickup-product-add {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;

    .header {
        margin-bottom: 30px;
        
        h1 {
            font-size: 24px;
            margin-bottom: 8px;
        }

        p {
            color: #666;
        }
    }

    .search-section {
        position: relative;
        margin-bottom: 30px;

        .search-container {
            display: flex;
            align-items: center;
            background: white;
            border: 1px solid #ddd;
            border-radius: 8px;
            padding: 12px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.05);

            .material-symbols-outlined {
                color: #666;
                margin-right: 10px;
            }

            input {
                flex: 1;
                border: none;
                outline: none;
                font-size: 16px;

                &::placeholder {
                    color: #999;
                }
            }
        }

        .search-results {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            background: white;
            border-radius: 8px;
            box-shadow: 0 4px 12px rgba(0,0,0,0.1);
            margin-top: 4px;
            max-height: 300px;
            overflow-y: auto;
            z-index: 1000;

            .search-result-item {
                display: flex;
                align-items: center;
                padding: 12px;
                cursor: pointer;
                transition: background-color 0.2s;

                &:hover {
                    background-color: #f5f5f5;
                }

                img {
                    width: 50px;
                    height: 50px;
                    object-fit: cover;
                    border-radius: 4px;
                    margin-right: 12px;
                }

                .product-info {
                    h3 {
                        font-size: 16px;
                        margin-bottom: 4px;
                    }

                    p {
                        color: #666;
                    }
                }
            }
        }
    }

    .selected-products {
        background: white;
        border-radius: 8px;
        padding: 20px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.05);

        h2 {
            font-size: 20px;
            margin-bottom: 20px;
        }

        .empty-state {
            text-align: center;
            padding: 40px;
            color: #666;

            .material-symbols-outlined {
                font-size: 48px;
                margin-bottom: 12px;
            }
        }

        .product-list {
            .product-item {
                display: flex;
                align-items: center;
                padding: 16px;
                border-bottom: 1px solid #eee;

                &:last-child {
                    border-bottom: none;
                }

                img {
                    width: 80px;
                    height: 80px;
                    object-fit: cover;
                    border-radius: 4px;
                    margin-right: 16px;
                }

                .product-details {
                    flex: 1;

                    h3 {
                        font-size: 16px;
                        margin-bottom: 8px;
                    }

                    .price {
                        color: #666;
                        margin-bottom: 8px;
                    }

                    .quantity-controls {
                        display: flex;
                        align-items: center;
                        gap: 12px;

                        button {
                            width: 30px;
                            height: 30px;
                            border: 1px solid #ddd;
                            border-radius: 4px;
                            background: white;
                            cursor: pointer;
                            transition: all 0.2s;

                            &:hover {
                                background: #f5f5f5;
                            }
                        }
                    }
                }

                .product-total {
                    margin: 0 20px;
                    font-weight: 500;
                }

                .remove-button {
                    background: none;
                    border: none;
                    color: #ff4444;
                    cursor: pointer;
                    padding: 8px;
                    transition: color 0.2s;

                    &:hover {
                        color: #cc0000;
                    }
                }
            }
        }
    }

    .order-summary {
        background: white;
        border-radius: 8px;
        padding: 20px;
        margin-top: 20px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.05);

        .total {
            display: flex;
            justify-content: space-between;
            font-size: 18px;
            font-weight: 500;
        }
    }

    .error-message {
        color: #ff4444;
        margin: 20px 0;
        padding: 12px;
        background: #ffebee;
        border-radius: 4px;
    }

    .action-buttons {
        display: flex;
        justify-content: flex-end;
        gap: 12px;
        margin-top: 20px;

        button {
            padding: 12px 24px;
            border-radius: 6px;
            font-size: 16px;
            cursor: pointer;
            transition: all 0.2s;

            &.cancel-button {
                background: white;
                border: 1px solid #ddd;

                &:hover {
                    background: #f5f5f5;
                }
            }

            &.save-button {
                background: var(--click-color);
                color: white;
                border: none;

                &:disabled {
                    background: #ccc;
                    cursor: not-allowed;
                }
            }
        }
    }
}

// Responsive Breakpoints
@media (max-width: 768px) {
    .quick-pickup-product-add {
        padding: 16px;

        .selected-products {
            .product-item {
                flex-direction: column;
                text-align: center;

                img {
                    margin: 0 0 12px 0;
                }

                .product-details {
                    margin-bottom: 12px;

                    .quantity-controls {
                        justify-content: center;
                    }
                }

                .product-total {
                    margin: 12px 0;
                }
            }
        }

        .action-buttons {
            flex-direction: column;
            
            button {
                width: 100%;
            }
        }
    }
}

@media (max-width: 480px) {
    .quick-pickup-product-add {
        .header {
            h1 {
                font-size: 20px;
            }
        }

        .search-section {
            .search-container {
                padding: 8px;
            }
        }
    }
}
