.smart-wash-quick-pickup-summary {
    max-width: 800px;
    margin: 2rem auto;
    padding: 1.5rem;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);

    h1 {
        color: #2c3e50;
        font-size: 1.8rem;
        margin-bottom: 2rem;
        text-align: center;
        font-weight: 600;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            bottom: -0.5rem;
            left: 50%;
            transform: translateX(-50%);
            width: 60px;
            height: 3px;
            background: var(--click-color);
            border-radius: 2px;
        }
    }

    .quick-pickup-summary-item {
        background: #f8f9fa;
        padding: 1.5rem;
        border-radius: 8px;
        margin-bottom: 1.5rem;
        transition: all 0.3s ease;

        &:hover {
            background: #ffffff;
            box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
            transform: translateY(-2px);
        }

        h2 {
            color: #34495e;
            font-size: 1.2rem;
            margin-bottom: 1rem;
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: 0.5rem;

            &:before {
                content: '';
                width: 4px;
                height: 1em;
                background: var(--click-color);
                border-radius: 2px;
            }
        }

        p {
            color: #2c3e50;
            font-size: 0.95rem;
            margin: 0.5rem 0;
            line-height: 1.5;
            display: flex;
            align-items: center;
            gap: 0.5rem;

            &:not(:last-child) {
                margin-bottom: 0.5rem;
            }
        }

        // Specific styling for personal details
        &:nth-child(2) {
            p {
                font-weight: 500;
                
                &:before {
                    content: attr(data-label);
                    color: #666;
                    font-weight: normal;
                    min-width: 80px;
                }
            }
        }

        // Specific styling for address
        &:nth-child(3) {
            p {
                padding-left: 1rem;
                position: relative;

                &:before {
                    content: '•';
                    position: absolute;
                    left: 0;
                    color: var(--click-color);
                }
            }
        }

        // Specific styling for clothes quantity
        &:nth-child(4) {
            p {
                font-size: 1.1rem;
                font-weight: 500;
                // color: #3498db;
            }
        }
    }

    .quick-pickup-summarythank-you-message {
        text-align: center;
        margin-bottom: 2rem;
        padding: 1.5rem;
        background: #f0f9ff;
        border-radius: 8px;

        .material-symbols-outlined {
            font-size: 3rem;
            color: #2ecc71;
            margin-bottom: 1rem;
        }

        h2 {
            color: #2c3e50;
            font-size: 1.4rem;
            margin-bottom: 1rem;
        }

        p {
            color: #34495e;
            font-size: 1rem;
            
            strong {
                color: #2c3e50;
            }
        }
    }

    .quick-pickup-summary-see-all-orders-btn {
        background: var(--click-color);
        color: #fff;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 4px;
    }
}

// Large screens (1200px and up)
@media screen and (min-width: 1200px) {
    .smart-wash-quick-pickup-summary {
        padding: 2rem;
        
        .quick-pickup-summary-item {
            padding: 1.75rem;
        }
    }
}

// Medium screens (992px to 1199px)
@media screen and (max-width: 1199px) {
    .smart-wash-quick-pickup-summary {
        margin: 1.5rem auto;
    }
}

// Tablet screens (768px to 991px)
@media screen and (max-width: 991px) {
    .smart-wash-quick-pickup-summary {
        margin: 1rem;
        
        h1 {
            font-size: 1.6rem;
        }

        .quick-pickup-summary-item {
            padding: 1.25rem;
            
            h2 {
                font-size: 1.1rem;
            }
        }
    }
}

// Mobile screens (576px to 767px)
@media screen and (max-width: 767px) {
    .smart-wash-quick-pickup-summary {
        margin: 0.75rem;
        padding: 1rem;

        h1 {
            font-size: 1.4rem;
            margin-bottom: 1.5rem;
        }

        .quick-pickup-summary-item {
            padding: 1rem;
            margin-bottom: 1rem;

            h2 {
                font-size: 1rem;
            }

            p {
                font-size: 0.9rem;
            }
        }

        .quick-pickup-summarythank-you-message {
            padding: 1rem;
            margin-bottom: 1.5rem;

            .material-symbols-outlined {
                font-size: 2.5rem;
            }

            h2 {
                font-size: 1.2rem;
            }

            p {
                font-size: 0.9rem;
            }
        }
    }
}

// Small mobile screens (up to 575px)
@media screen and (max-width: 575px) {
    .smart-wash-quick-pickup-summary {
        margin: 0.5rem;
        border-radius: 8px;

        h1 {
            font-size: 1.3rem;
            
            &:after {
                width: 40px;
            }
        }

        .quick-pickup-summary-item {
            p {
                font-size: 0.85rem;
            }
        }
    }
}

// Print styles
@media print {
    .smart-wash-quick-pickup-summary {
        margin: 0;
        padding: 1rem;
        box-shadow: none;
        
        .quick-pickup-summary-item {
            break-inside: avoid;
            page-break-inside: avoid;
            background: none;
            box-shadow: none;
        }
    }
}
