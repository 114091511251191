.smart-wash-reorder-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    color: #333;
    font-family: 'Roboto', sans-serif;
    
    .header {
      text-align: center;
      margin-bottom: 2rem;
      
      h1 {
        font-size: 2.2rem;
        margin-bottom: 0.5rem;
        color: #3f51b5;
      }
      
      p {
        font-size: 1.1rem;
        color: #9e9e9e;
      }
    }
    
    .order-details {
      background-color: #f5f5f5;
      border-radius: 8px;
      padding: 1.5rem;
      margin-bottom: 2rem;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      
      h2 {
        margin-top: 0;
        margin-bottom: 1.2rem;
        font-size: 1.5rem;
        color: #3f51b5;
        border-bottom: 2px solid #e0e0e0;
        padding-bottom: 0.5rem;
      }
      
      .details-grid {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 2rem;
        
        div {
          flex: 1;
          min-width: 250px;
          
          strong {
            display: block;
            margin-bottom: 0.75rem;
            font-size: 1.1rem;
          }
          
          p {
            margin: 0.3rem 0;
            color: #333;
          }
        }
      }
    }
    
    .search-section {
      position: relative;
      margin-bottom: 2rem;
      
      .search-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 24px;
        padding: 0.5rem 1rem;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        
        .material-symbols-outlined {
          margin-right: 0.5rem;
          color: #9e9e9e;
        }
        
        input {
          flex: 1;
          border: none;
          font-size: 1rem;
          padding: 0.5rem;
          outline: none;
          
          &::placeholder {
            color: #9e9e9e;
          }
        }
      }
      
      .search-results {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 10;
        background-color: #fff;
        border-radius: 8px;
        max-height: 300px;
        overflow-y: auto;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
        margin-top: 0.5rem;
        
        .search-result-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 1rem;
          cursor: pointer;
          border-bottom: 1px solid #e0e0e0;
          transition: all 0.3s ease;
          
          &:last-child {
            border-bottom: none;
          }
          
          &:hover {
            background-color: #f5f5f5;
          }
          
          img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 8px;
            margin-right: 1rem;
          }
          
          .product-info {
            flex: 1;
            
            h3 {
              margin: 0 0 0.3rem 0;
              font-size: 1rem;
            }
            
            p {
              margin: 0;
              color: #3f51b5;
              font-weight: bold;
            }
          }
        }
      }
    }
    
    .selected-products {
      margin-bottom: 2rem;
      
      h2 {
        margin-top: 0;
        margin-bottom: 1.2rem;
        font-size: 1.5rem;
        color: #3f51b5;
        border-bottom: 2px solid #e0e0e0;
        padding-bottom: 0.5rem;
      }
      
      .product-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        border-radius: 8px;
        padding: 1rem;
        margin-bottom: 1rem;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;
        
        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        }
        
        img {
          width: 70px;
          height: 70px;
          object-fit: cover;
          border-radius: 8px;
          margin-right: 1rem;
        }
        
        .product-details {
          flex: 1;
          
          h3 {
            margin: 0 0 0.3rem 0;
            font-size: 1.1rem;
          }
          
          p {
            margin: 0;
            color: #3f51b5;
            font-weight: bold;
            font-size: 1.05rem;
          }
        }
        
        .quantity-controls {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin: 0 1rem;
          
          button {
            background: none;
            border: none;
            padding: 0;
            cursor: pointer;
            outline: none;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #f5f5f5;
            color: #333;
            font-size: 1.2rem;
            font-weight: bold;
            transition: all 0.3s ease;
            
            &:hover {
              background-color: #e0e0e0;
            }
          }
          
          span {
            margin: 0 0.8rem;
            font-weight: bold;
            min-width: 25px;
            text-align: center;
          }
        }
        
        .remove-btn {
          background: none;
          border: none;
          padding: 0;
          cursor: pointer;
          outline: none;
          color: #f50057;
          font-weight: 500;
          transition: all 0.3s ease;
          
          &:hover {
            color: #c51162;
            text-decoration: underline;
          }
        }
      }
    }
    
    .error-message {
      background-color: #ffebee;
      color: #f44336;
      padding: 0.8rem 1rem;
      border-radius: 8px;
      margin-bottom: 1.5rem;
      font-weight: 500;
      text-align: center;
    }
    
    .order-summary {
      background-color: #fff;
      border-radius: 8px;
      padding: 1.5rem;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      
      .total {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;
        font-size: 1.2rem;
        
        span {
          font-size: 1.5rem;
          color: #3f51b5;
          font-weight: bold;
        }
      }
      
      .reorder-btn {
        width: 100%;
        background-color: #3f51b5;
        color: #fff;
        border: none;
        border-radius: 8px;
        padding: 1rem;
        font-size: 1.1rem;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.3s ease;
        
        &:hover {
          background-color: #303f9f;
        }
        
        &:disabled {
          background-color: #9e9e9e;
          cursor: not-allowed;
        }
      }
    }
  }
  
  // Responsive breakpoints
  @media (max-width: 1024px) {
    .smart-wash-reorder-container {
      padding: 1.5rem;
      
      .header {
        h1 {
          font-size: 2rem;
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    .smart-wash-reorder-container {
      padding: 1rem;
      
      .header {
        h1 {
          font-size: 1.8rem;
        }
        
        p {
          font-size: 1rem;
        }
      }
      
      .product-item {
        flex-wrap: wrap;
        
        img {
          width: 60px;
          height: 60px;
        }
        
        .product-details {
          width: calc(100% - 80px);
          margin-bottom: 1rem;
        }
        
        .quantity-controls {
          margin-left: 80px;
          margin-right: auto;
        }
        
        .remove-btn {
          margin-left: auto;
        }
      }
    }
  }
  
  @media (max-width: 480px) {
    .smart-wash-reorder-container {
      .header {
        h1 {
          font-size: 1.6rem;
        }
      }
      
      .order-details {
        padding: 1rem;
        
        .details-grid {
          flex-direction: column;
          gap: 1.5rem;
          
          div {
            width: 100%;
          }
        }
      }
      
      .product-item {
        padding: 0.8rem;
        
        img {
          width: 50px;
          height: 50px;
        }
        
        .product-details {
          width: calc(100% - 70px);
          
          h3 {
            font-size: 1rem;
          }
        }
        
        .quantity-controls {
          margin-left: 70px;
          
          button {
            width: 26px;
            height: 26px;
            font-size: 1rem;
          }
          
          span {
            margin: 0 0.5rem;
          }
        }
      }
      
      .order-summary {
        padding: 1rem;
        
        .total {
          font-size: 1.1rem;
          
          span {
            font-size: 1.3rem;
          }
        }
        
        .reorder-btn {
          padding: 0.8rem;
          font-size: 1rem;
        }
      }
    }
  }