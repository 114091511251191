.smart-wash-quick-pickup {
    max-width: 800px;
    margin: 1.5rem auto;
    padding: 1.5rem;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);

    h1 {
        color: #2c3e50;
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
        text-align: center;
        font-weight: 600;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .smart-wash-quantity-selector, .smart-wash-address-form, .smart-wash-form-section {
            background: #f8f9fa;
            padding: 1.25rem;
            border-radius: 8px;
            transition: all 0.3s ease;

            &:hover {
                background: #ffffff;
                box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
            }

            h3 {
                color: #34495e;
                font-size: 1rem;
                margin-bottom: 0.75rem;
                font-weight: 500;
            }
        }

        .smart-wash-quantity-selector {
            select {
                width: 100%;
                padding: 0.7rem;
                border: 1px solid #e0e0e0;
                border-radius: 6px;
                font-size: 0.9rem;
                color: #2c3e50;
                background-color: white;
                cursor: pointer;
                transition: border-color 0.3s ease;

                &:focus {
                    outline: none;
                    border-color: #3498db;
                }

                &:hover {
                    border-color: #3498db;
                }

                option {
                    padding: 10px;
                }
            }
        }

        .smart-wash-address-form {
            display: grid;
            gap: 0.75rem;

            input {
                width: 100%;
                padding: 0.7rem;
                border: 1px solid #e0e0e0;
                border-radius: 6px;
                font-size: 0.9rem;
                color: #2c3e50;
                transition: all 0.3s ease;

                &:focus {
                    outline: none;
                    border-color: #3498db;
                    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.1);
                }

                &::placeholder {
                    color: #95a5a6;
                }
            }
        }

        .smart-wash-error {
            color: #e74c3c;
            background: #fde8e8;
            padding: 0.75rem;
            border-radius: 6px;
            font-size: 0.85rem;
            text-align: center;
        }

        .smart-wash-quick-pickup-submit-btn {
            background: var(--click-color);
            color: white;
            padding: 0.8rem;
            border: none;
            border-radius: 6px;
            font-size: 0.9rem;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.3s ease;
            margin-top: 0.75rem;

            &:hover {
                background: var(--click-color);
                transform: translateY(-1px);
            }

            &:disabled {
                background: #bdc3c7;
                cursor: not-allowed;
                transform: none;
            }
        }

        .smart-wash-form-section {
            background: #f8f9fa;
            padding: 1.5rem;
            border-radius: 8px;
            transition: all 0.3s ease;

            &:hover {
                background: #ffffff;
                box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
            }

            h3 {
                color: #34495e;
                font-size: 1.2rem;
                margin-bottom: 1rem;
                font-weight: 500;
            }

            .smart-wash-info-display {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 1rem;
        
                .smart-wash-info-item {
                    display: flex;
                    flex-direction: column;
                    gap: 0.3rem;
        
                    label {
                        font-size: 0.85rem;
                        color: #666;
                        font-weight: 500;
                    }
        
                    span {
                        font-size: 0.95rem;
                        color: #2c3e50;
                        padding: 0.5rem;
                        background: #c5c5c5;
                        border-radius: 4px;
                    }
                }
            }
        }

        .smart-wash-input-group {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0.75rem;
        }

        .smart-wash-address-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.75rem;

            .existing-address-btn {
                background: none;
                border: none;
                color: var(--click-color);
                font-size: 0.85rem;
                cursor: pointer;
                padding: 0.4rem 0.8rem;
                border-radius: 4px;
                transition: all 0.3s ease;
            }
        }
    }


}

// Modal Styles
.smart-wash-address-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .smart-wash-address-modal {
        background: white;
        padding: 1rem;
        border-radius: 12px;
        width: 90%;
        max-width: 700px;
        max-height: 80vh;
        overflow-y: auto;

        h3 {
            margin-bottom: 1rem;
            color: #2c3e50;
            font-size: 1.1rem;
        }

        .smart-wash-address-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;

            .smart-wash-address-item {
                padding: 10px;
                border: 1px solid #e0e0e0;
                border-radius: 8px;
                cursor: pointer;
                transition: all 0.3s ease;
                min-width: 200px;

                &:hover {
                    border-color: #3498db;
                    background: #f8f9fa;
                }

                p {
                    margin: 0.15rem 0;
                    color: #2c3e50;
                    font-size: 0.9rem;

                    &:first-child {
                        font-weight: 500;
                    }
                }
            }
        }

        .smart-wash-modal-actions {
            margin-top: 10px;
            display: flex;
            justify-content: flex-end;

            button {
                padding: 10px;
                border-radius: 6px;
                font-size: 0.85rem;
                cursor: pointer;
                transition: all 0.3s ease;

                &.smart-wash-cancel-btn {
                    background: #f8f9fa;
                    border: 1px solid #e0e0e0;
                    color: #2c3e50;
                }
            }
        }
    }
}

// Responsive Breakpoints
@media screen and (max-width: 1024px) {
    .smart-wash-quick-pickup {
        margin: 1.25rem;
        padding: 1.25rem;

        h1 {
            font-size: 1.6rem;
        }
    }
}

@media screen and (max-width: 768px) {
    .smart-wash-quick-pickup {
        margin: 0.75rem;
        padding: 0.75rem;

        h1 {
            font-size: 1.4rem;
        }

        form {
            gap: 1.25rem;

            .smart-wash-quantity-selector, .smart-wash-address-form {
                padding: 1rem;
            }

            .smart-wash-input-group {
                grid-template-columns: 1fr;
            }
        }
    }

    .smart-wash-address-modal {
        width: 95%;
        padding: 1.5rem;

        .smart-wash-address-list {
            flex-direction: column;
            align-items: flex-start;
            
            .smart-wash-address-item {
                width: 100%;
                padding: 5px;
                border: 1px solid #e0e0e0;
                border-radius: 8px;
                cursor: pointer;
            }
        }

    }
}

@media screen and (max-width: 480px) {
    .smart-wash-quick-pickup {
        margin: 0.5rem;
        padding: 0.75rem;

        h1 {
            font-size: 1.3rem;
            margin-bottom: 1rem;
        }

        .smart-wash-info-display {
            grid-template-columns: 1fr;
            gap: 0.75rem;
        }

        form {
            gap: 0.75rem;

            .smart-wash-quantity-selector, .smart-wash-address-form {
                h3 {
                    font-size: 0.95rem;
                }
            }

            button {
                padding: 0.7rem;
                font-size: 0.85rem;
            }

            .smart-wash-address-header {
                flex-direction: column;
                align-items: flex-start;
                gap: 0.5rem;
            }
        }
    }

    .smart-wash-address-modal {
        padding: 1rem;

        .smart-wash-address-list {
            flex-direction: column;
            align-items: flex-start;
            
            .smart-wash-address-item {
                width: 100%;
                padding: 5px;
                border: 1px solid #e0e0e0;
                border-radius: 8px;
                cursor: pointer;
            }
        }
    }
}
